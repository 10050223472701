import React from "react";
import "./App.css";
import CoolLandingPage from "./CoolLandingPage";

function App() {
  return (
    <div className="App">
      <CoolLandingPage
        logo="/path/to/your/logo.png"
        subtitle={process.env.REACT_APP_SUBTITLE}
        title={process.env.REACT_APP_TITLE}
        description={process.env.REACT_APP_DESCRIPTION}
        loadingTime={parseInt(process.env.REACT_APP_LOADING_TIME)}
        logoSize={process.env.REACT_APP_LOGO_SIZE}
        gradient={process.env.REACT_APP_GRADIENT}
        homeLink={process.env.REACT_APP_HOME_LINK}
      />
    </div>
  );
}

export default App;
