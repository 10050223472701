import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Home } from "lucide-react";
import { motion } from "framer-motion";
import styles from "./CoolLandingPage.module.css";

const gradients = {
  purple: styles.gradientPurple,
  blue: styles.gradientBlue,
  brown: styles.gradientBrown,
  green: styles.gradientGreen,
  yellow: styles.gradientYellow,
};

const CoolLandingPage = ({
  logo,
  subtitle,
  title,
  description,
  loadingTime,
  logoSize,
  gradient,
  homeLink,
  videoTransitionInterval = 8000,
  crossFadeDuration = 2000,
  playbackRate = 1,
}) => {
  const [loading, setLoading] = useState(true);
  const [gradientClass, setGradientClass] = useState("");
  const [activeVideo, setActiveVideo] = useState(0);
  const [seconds, setSeconds] = useState(1);
  const [transitionSeconds, setTransitionSeconds] = useState(null);
  const [videoStatus, setVideoStatus] = useState({ 0: "playing", 1: "paused" });
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRefs = useMemo(() => [videoRef1, videoRef2], []);
  const transitionTimeoutRef = useRef(null);
  const siteNameRef = useRef(null);

  const showCounter = process.env.REACT_APP_SHOW_COUNTER === "true";

  useEffect(() => {
    setGradientClass(gradients[gradient] || styles.gradientPurple);

    const timer = setTimeout(() => {
      setLoading(false);
    }, loadingTime || 2000);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [gradient, loadingTime]);

  useEffect(() => {
    const adjustSiteNameSize = () => {
      if (siteNameRef.current && isMobile) {
        const container = siteNameRef.current;
        const maxHeight = window.innerHeight * (2.3 / 3);
        let fontSize = parseInt(window.getComputedStyle(container).fontSize);

        while (container.scrollHeight > maxHeight && fontSize > 10) {
          fontSize -= 1;
          container.style.fontSize = `${fontSize}px`;
        }
      }
    };

    adjustSiteNameSize();
    window.addEventListener("resize", adjustSiteNameSize);

    return () => {
      window.removeEventListener("resize", adjustSiteNameSize);
    };
  }, [isMobile]);

  const startTransition = useCallback(() => {
    setTransitionSeconds(1);
    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current);
    }

    const nextVideo = activeVideo === 0 ? 1 : 0;
    if (videoRefs[nextVideo].current) {
      videoRefs[nextVideo].current.currentTime = 0;
      videoRefs[nextVideo].current.play();
      setVideoStatus((prev) => ({ ...prev, [nextVideo]: "playing" }));
    }

    transitionTimeoutRef.current = setTimeout(() => {
      setActiveVideo(nextVideo);
      setSeconds(1);
      setTransitionSeconds(null);

      if (videoRefs[activeVideo].current) {
        videoRefs[activeVideo].current.pause();
        setVideoStatus((prev) => ({ ...prev, [activeVideo]: "paused" }));
      }
    }, crossFadeDuration);
  }, [activeVideo, crossFadeDuration, videoRefs]);

  useEffect(() => {
    const transitionInterval = setInterval(() => {
      startTransition();
    }, videoTransitionInterval);

    return () => {
      clearInterval(transitionInterval);
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
      }
    };
  }, [videoTransitionInterval, startTransition]);

  useEffect(() => {
    if (videoRefs[0].current && videoRefs[1].current) {
      videoRefs[0].current.play();
      videoRefs[1].current.pause();
      setVideoStatus({ 0: "playing", 1: "paused" });
    }

    videoRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.playbackRate = playbackRate;
      }
    });
  }, [videoRefs, playbackRate]);

  useEffect(() => {
    if (showCounter) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
        if (transitionSeconds !== null) {
          setTransitionSeconds((prevSeconds) => prevSeconds + 1);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [showCounter, transitionSeconds]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--crossFadeDuration",
      `${crossFadeDuration}ms`
    );
  }, [crossFadeDuration]);

  const siteName = process.env.REACT_APP_SITE_NAME;
  const hideHomeButton = process.env.REACT_APP_HIDE_HOME_BUTTON === "true";

  const letterVariants = {
    initial: { y: 50, opacity: 0, rotateX: -90 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      rotateX: 0,
      transition: {
        delay: i * 0.05,
        duration: 0.8,
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    }),
  };

  const containerVariants = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const renderSiteNameContent = (name) => {
    return name.split("").map((char, index) => (
      <motion.span
        key={index}
        variants={letterVariants}
        custom={index}
        className={styles.siteNameLetter}
      >
        {char}
      </motion.span>
    ));
  };

  const logoOrSiteName = siteName ? (
    <motion.h1
      className={styles.siteName}
      variants={containerVariants}
      initial="initial"
      animate="animate"
      ref={siteNameRef}
    >
      {isMobile && !isLandscape
        ? siteName.split(" ").map((word, index) => (
            <div key={index} className={styles.siteNameWord}>
              {renderSiteNameContent(word + " ")}
            </div>
          ))
        : renderSiteNameContent(siteName)}
    </motion.h1>
  ) : (
    <img
      src={logo}
      alt="Logo"
      className={styles.logo}
      style={{ maxWidth: logoSize, height: "auto" }}
      onError={(e) => {
        e.target.onerror = null;
        e.target.parentElement.innerHTML =
          '<div class="' + styles.logoFallback + '">Logo</div>';
      }}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.videoBackground}>
        <video
          ref={videoRef1}
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          className={`${styles.video} ${
            activeVideo === 0 ? styles.fadeIn : styles.fadeOut
          }`}
        >
          <source src="/tech-background.mp4" type="video/mp4" />
        </video>
        <video
          ref={videoRef2}
          loop
          muted
          playsInline
          preload="auto"
          className={`${styles.video} ${
            activeVideo === 1 ? styles.fadeIn : styles.fadeOut
          }`}
        >
          <source src="/second-background.mp4" type="video/mp4" />
        </video>
      </div>
      <div className={`${styles.gradientOverlay} ${gradientClass}`}></div>
      <div className={styles.content}>
        {showCounter && (
          <div className={styles.counters}>
            <div className={styles.counter}>{seconds} seconds</div>
            {transitionSeconds !== null && (
              <div className={styles.transitionCounter}>
                {transitionSeconds} seconds in transition
              </div>
            )}
            <div className={styles.videoStatus}>
              Video 1:{" "}
              <span
                className={
                  videoStatus[0] === "playing" ? styles.playing : styles.paused
                }
              >
                {videoStatus[0]}
              </span>
              , Video 2:{" "}
              <span
                className={
                  videoStatus[1] === "playing" ? styles.playing : styles.paused
                }
              >
                {videoStatus[1]}
              </span>
            </div>
            <div className={styles.activeVideo}>
              Active Video: {activeVideo + 1}
            </div>
          </div>
        )}
        <div className={styles.logoContainer}>
          {logoOrSiteName}
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
        <div className={styles.contentContainer}>
          {loading ? (
            <div className={styles.spinner}></div>
          ) : (
            <div className={`${styles.contentInner} ${styles.contentVisible}`}>
              <div className={styles.contentShape}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.description}>{description}</p>
              </div>
              {!hideHomeButton && (
                <div className={styles.homeLink}>
                  <a href={homeLink}>
                    <Home size={24} />
                    <span>Go to Home</span>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoolLandingPage;
